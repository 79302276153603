import { lazy } from "react";
import { useRoutes } from "react-router-dom";
const Home = lazy(() => import("../pages/home/Home"));
const Clients = lazy(() => import("../pages/clients/Clients"));
const AboutUs = lazy(() => import("../pages/about/AboutUs"));
const Services = lazy(() => import("../pages/services/Services"));
const Technologies = lazy(() => import("../pages/technologies/Technologies"));
const Life = lazy(() => import("../pages/life/Life"));
const Contacts = lazy(() => import("../pages/contacts/Contacts"));
const Work = lazy(() => import("../pages/work/Work"));
const Fa8e = lazy(() => import("../pages/fa8e/Fa8e"));
const Instacam = lazy(() => import("../pages/instacam/InstaCam"));
const Spomo = lazy(() => import("../pages/spomo/Spomo"));
const Priceless = lazy(() => import("../pages/priceless/Priceless"));
const HomeWorkOut = lazy(() => import("../pages/HomeWorkOut/HomeWorkOut"));
const Crafty = lazy(() => import("../pages/craftycanineclub/CraftyCaninClub"));
const Puza = lazy(() => import("../pages/phuza/Phuza"));
const Career = lazy(() => import("../pages/career/Career"));
export default function AllRoutes() {
  let routes = useRoutes([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/services",
      element: <Services />,
    },
    {
      path: "/technologies",
      element: <Technologies />,
    },
    {
      path: "/life",
      element: <Life />,
    },
    {
      path: "/contacts",
      element: <Contacts />,
    },
    {
      path: "/work",
      element: <Work />,
    },
    {
      path: "about",
      element: <AboutUs />,
    },
    {
      path: "clients",
      element: <Clients />,
    },
    {
      path: "/fa8e",
      element: <Fa8e />,
    },
    {
      path: "/instacam",
      element: <Instacam />,
    },
    {
      path: "/spomo",
      element: <Spomo />,
    },
    {
      path: "/priceless",
      element: <Priceless />,
    },
    {
      path: "/homeworkout",
      element: <HomeWorkOut />,
    },
    {
      path: "/crafty",
      element: <Crafty />,
    },
    {
      path: "/puza",
      element: <Puza />,
    },
    {
      path: "/career",
      element: <Career />,
    },

    {
      path: "*",
      element: <Home />,
    },
  ]);
  return routes;
}
