import * as React from "react";

import Drawer from "@mui/material/Drawer";
import { NavLink, useNavigate } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";
import { GiLifeBar, GiHumanPyramid } from "react-icons/gi";
import { useMediaQuery, useMediaQueries } from "@react-hook/media-query";

import {
  MdConnectWithoutContact,
  MdMiscellaneousServices,
  MdBiotech,
} from "react-icons/md";
import { IoCodeSlash } from "react-icons/io5";
import { SiAboutdotme } from "react-icons/si";
import { useState } from "react";
export default function SideDrawer({ state, toggle, onClose }) {
  const matches = useMediaQuery("(max-width: 990px)");
  const navigate = useNavigate();
  const onNavigationChange = (key) => {
    navigate(key);
    onClose();
  };
  return (
    <div>
      <React.Fragment>
        {matches ? (
          <>
            <Drawer anchor={"left"} open={state} onClose={toggle}>
              <div className="drawer-style" style={{ margin: "2rem 0rem" }}>
                <div
                  style={{
                    alignItems: "center",
                    marginTop: "0.5rem",
                  }}
                  className="navigation_mobile"
                >
                  <div style={{ paddingLeft: "0.5rem", paddingTop: "0.5rem" }}>
                    <div
                      onClick={() => onNavigationChange("/")}
                      className="sidebar-link"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <span>
                        <AiOutlineHome
                          style={{ height: "2rem", width: "2rem" }}
                          className="mobile_navicon"
                        />
                      </span>
                      <span
                        style={{
                          marginLeft: "0.5rem",
                          position: "relative",
                          top: "-5px",
                        }}
                        className="mobile_content"
                        // onClick={homenow}
                      >
                        <b>HOME</b>
                      </span>
                    </div>
                    <br />
                  </div>
                </div>
                <div
                  style={{ alignItems: "center", marginTop: "0.5rem" }}
                  className="navigation_mobile"
                >
                  <div style={{ paddingLeft: "0.5rem", paddingTop: "0.5rem" }}>
                    <div
                      onClick={() => onNavigationChange("/services")}
                      className="sidebar-link"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <span>
                        <MdMiscellaneousServices
                          style={{ height: "2rem", width: "2rem" }}
                          className="mobile_navicon"
                        />
                      </span>
                      <span
                        style={{
                          marginLeft: "0.5rem",
                          position: "relative",
                          top: "-5px",
                        }}
                        className="mobile_content"
                      >
                        <b>Services</b>
                      </span>
                    </div>
                    <br />
                  </div>
                </div>
                <div
                  style={{ alignItems: "center", marginTop: "0.5rem" }}
                  className="navigation_mobile"
                >
                  <div style={{ paddingLeft: "0.5rem", paddingTop: "0.5rem" }}>
                    <div
                      onClick={() => onNavigationChange("/technologies")}
                      className="sidebar-link"
                      style={{ textDecoration: "none" }}
                    >
                      <span>
                        <MdBiotech
                          style={{ height: "2rem", width: "2rem" }}
                          className="mobile_navicon"
                        />
                      </span>
                      <span
                        style={{
                          marginLeft: "0.5rem",
                          position: "relative",
                          top: "-5px",
                          color: "black",
                        }}
                        className="mobile_content"
                      >
                        <b>Technologies</b>
                      </span>
                    </div>
                    <br />
                  </div>
                </div>
                <div
                  style={{ alignItems: "center", marginTop: "0.5rem" }}
                  className="navigation_mobile"
                >
                  <div style={{ paddingLeft: "0.5rem", paddingTop: "0.5rem" }}>
                    <div
                      onClick={() => onNavigationChange("/life")}
                      className="sidebar-link"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <span>
                        <GiLifeBar
                          style={{ height: "2rem", width: "2rem" }}
                          className="mobile_navicon"
                        />
                      </span>
                      <span
                        style={{
                          marginLeft: "0.5rem",
                          position: "relative",
                          top: "-5px",
                        }}
                        className="mobile_content"
                      >
                        <b>Life@Sunfocus</b>
                      </span>
                    </div>
                    <br />
                  </div>
                </div>
                <div
                  style={{ alignItems: "center", marginTop: "0.5rem" }}
                  className="navigation_mobile"
                >
                  <div style={{ paddingLeft: "0.5rem", paddingTop: "0.5rem" }}>
                    <div
                      onClick={() => onNavigationChange("contacts")}
                      className="sidebar-link"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <span>
                        <MdConnectWithoutContact
                          style={{ height: "2rem", width: "2rem" }}
                          className="mobile_navicon"
                        />
                      </span>
                      <span
                        style={{
                          marginLeft: "0.5rem",
                          position: "relative",
                          top: "-5px",
                        }}
                        className="mobile_content"
                      >
                        <b>Contacts</b>
                      </span>
                    </div>
                    <br />
                  </div>
                </div>
                <div
                  style={{ alignItems: "center", marginTop: "0.5rem" }}
                  className="navigation_mobile"
                >
                  <div style={{ paddingLeft: "0.5rem", paddingTop: "0.5rem" }}>
                    <div
                      onClick={() => onNavigationChange("/work")}
                      className="sidebar-link"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <span>
                        <IoCodeSlash
                          style={{ height: "2rem", width: "2rem" }}
                          className="mobile_navicon"
                        />
                      </span>
                      <span
                        style={{
                          marginLeft: "0.5rem",
                          position: "relative",
                          top: "-5px",
                        }}
                        className="mobile_content"
                      >
                        <b>Work</b>
                      </span>
                    </div>
                    <br />
                  </div>
                </div>
                <div
                  style={{ alignItems: "center", marginTop: "0.5rem" }}
                  className="navigation_mobile"
                >
                  <div style={{ paddingLeft: "0.5rem", paddingTop: "0.5rem" }}>
                    <div
                      onClick={() => onNavigationChange("about")}
                      className="sidebar-link"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <span>
                        <SiAboutdotme
                          style={{ height: "2rem", width: "2rem" }}
                          className="mobile_navicon"
                        />
                      </span>
                      <span
                        style={{
                          marginLeft: "0.5rem",
                          position: "relative",
                          top: "-7px",
                        }}
                        className="mobile_content"
                      >
                        <b>About</b>
                      </span>
                    </div>
                    <br />
                  </div>
                </div>
                <div
                  style={{ alignItems: "center", marginTop: "0.5rem" }}
                  className="navigation_mobile"
                >
                  <div style={{ paddingLeft: "0.5rem", paddingTop: "0.5rem" }}>
                    <div
                      onClick={() => onNavigationChange("clients")}
                      className="sidebar-link"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <span>
                        <GiHumanPyramid
                          style={{ height: "2rem", width: "2rem" }}
                          className="mobile_navicon"
                        />
                      </span>
                      <span
                        style={{
                          marginLeft: "0.5rem",
                          position: "relative",
                          top: "-5px",
                        }}
                        className="mobile_content"
                      >
                        <b>Clients</b>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Drawer>
          </>
        ) : null}
      </React.Fragment>
    </div>
  );
}
