import styled from "@emotion/styled";
import { Box } from "@mui/system";


export const CustomFluid=styled(Box)`
  width:100%;
`

export const CustomContainer = styled(Box)`
width:1440px;
max-width:100%;
margin:0 auto;
padding:0 15px;  

`