import styled from '@emotion/styled'
import { Box } from '@mui/material'
import React from 'react'

export default function Loader() {
    return (
        <Wrapper>

            <div className='loader-box'>
                <label>Loading........</label>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled(Box)`
width:100%;
height:100vh;
min-height:400px;
display:flex;
align-items:center;
justify-content:center;
position:relative;
`