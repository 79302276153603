import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import Loader from "./loader/Loader";
import AllRoutes from "./Routes/AllRoutes";
import "./styles/custom.scss";
import Navbar from "./components/Navbar";
import ScrollToTop from "./components/ScrollToTop";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function App() {
  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <div className="wrapper">
          <Navbar />
          <ScrollToTop />
          <AllRoutes />
        </div>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
