import { createTheme } from '@mui/material/styles';
import { blue, red, } from '@mui/material/colors';
export const theme = createTheme({
  fontFamily: [
    'Poppins',

    'sans-serif'
  
  ].join(','),

  // pellte customization
  palette: {
    primary: {
      light: '#468fc5',
      main: '#000',
      // main: '#1873B7',
      // dark: '#105080',
      contrastText: '#fff',
    },
    secondary: {
      light: 'red',
      main: 'red',
      dark: 'red',
      contrastText: '#fff',
    },
  },
  root: {
    "& .MuiOutlinedInput-root.Mui-focused": {
      borderColor: red,
      boxShadow: 40
    }
  },
  // components customization
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'dashed' },
          style: {
            textTransform: 'none',
            border: `2px dashed ${blue[500]}`,
          },
        },
        // {
        //   props: { variant: 'contained',color:'primary', },
        //   style: {
        //     textTransform: 'none',
        //      background:green[800],
        //      '&:hover':{
        //        background:green[600]
        //      }
        //   },
        // },
        {
          props: { variant: 'dashed', color: 'secondary' },
          style: {
            border: `4px dashed ${red[500]}`,
          },
        },
      ],
    },
    Mui: {
      '&:focus': {
        background: red,
      }
    }

    // MuiInputBase-root-MuiOutlinedInput-root

  },

})
