import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  CustomContainer,
  CustomFluid,
} from "../muiComponents/custumComponents";
import { Box } from "@mui/system";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { WhiteLogo, BlackLogo } from "../utils/images";
import * as GiIcons from "react-icons/gi";
import Drawer from "./Drawer";
export default function Navbar() {
  let navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  const [SidebarStatus, updateSidebarStatus] = useState(false);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  let contactnow = () => {
    navigate("/contacts");
  };

  //   const onNavigate()
  return (
    <CustomFluid className={offset > 200 ? "dark-nav" : "white-nav"}>
      <Drawer
        state={SidebarStatus}
        toggle={() => updateSidebarStatus(!SidebarStatus)}
        onClose={() => updateSidebarStatus(false)}
      />
      <CustomContainer>
        <NavBox sx={{ flexFlow: 1 }} className="nav-content">
          <Link to="/">
            <img
              src={offset > 200 ? BlackLogo : WhiteLogo}
              className="nav-logo"
              alt="sunfocus solution"
            />
          </Link>
          <Box className="nav-links">
            <NavLink
              to="/about"
              className={({ isActive }) => (isActive ? "active-link" : "link")}
            >
              About us
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? "active-link" : "link")}
              to="/services"
            >
              {" "}
              Services
            </NavLink>
            <NavLink
              to="/technologies"
              className={({ isActive }) => (isActive ? "active-link" : "link")}
            >
              Technologies
            </NavLink>
            <NavLink
              to="/work"
              className={({ isActive }) => (isActive ? "active-link" : "link")}
            >
              Work
            </NavLink>
            <NavLink
              to="/clients"
              className={({ isActive }) => (isActive ? "active-link" : "link")}
            >
              Clients
            </NavLink>
            <NavLink
              to="/life"
              className={({ isActive }) => (isActive ? "active-link" : "link")}
            >
              Life @ Sunfocus
            </NavLink>
            <NavLink
              to="/contacts"
              className={({ isActive }) => (isActive ? "active-link" : "link")}
            >
              Contacts
            </NavLink>
          </Box>
          <button className="shine-btn quote-btn" onClick={contactnow}>
            GEt A QUOTE
          </button>
          <div className="expand-btn" onClick={() => updateSidebarStatus(true)}>
            <GiIcons.GiHamburgerMenu />
          </div>
        </NavBox>
      </CustomContainer>
    </CustomFluid>
  );
}

const NavBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: transparent;
  align-items: center;
`;
